<template>
    <Transition name="slide-fade">
        <div v-show="visible" class="fxDisplay fxDirCol cartContainer">
            <div class="fxDisplay fxDirRow cartSummaryRow">
              <span class="cartSummaryHeader">cart</span>
              <span v-if="cartItems.length > 1 || cartItems.length === 0" class="cartSummarySubHeader">{{ cartItems.length }} items</span>
              <span v-if="cartItems.length === 1" class="cartSummarySubHeader">{{ cartItems.length }} item</span>
            </div>
            <div class="cartItemsContainer">
                <v-progress-circular v-if="loading" indeterminate size="large"></v-progress-circular>
                <div class="fxDisplay fxDirRowRev errorMsgContainer" v-if="errorMsg.length > 0">
                  <v-icon icon="mdi-close-circle-outline" class="quantityBtns" 
                      size="24" @click="errorMsg = ''">
                  </v-icon>
                  <h4 style="white-space: pre-line;">{{ errorMsg }}</h4>
                </div>
                <div class="fxDisplay fxDirRow cartHeaderContainer">
                  <div class="fxDisplay fxDirCol progressDeliveryContainer">
                    <div class="progressbarContainer">
                      <v-progress-linear
                        :color="progressBarColour"
                        :model-value="percentageTillFreeDelivery"
                        :height="8"
                        style="border-radius: 10px;"
                      ></v-progress-linear>
                    </div>
                    <h5 class="freeDeliveryText" v-if="remainingValueTillFreeDelivery > 0">€{{remainingValueTillFreeDelivery}} until free delivery</h5>
                    <h5 class="freeDeliveryText" v-if="remainingValueTillFreeDelivery === 0">Free delivery!</h5>

                  </div>
                </div>
                <!-- Stocked Items -->
                <div v-if="!loading && stockedCartItems.length > 0" style="width:100%">
                <!-- <h4 class="deliveryText">Delivery 2-4 days:</h4> -->
                <div class="fxDisplay fxDirCol itemsContainer">
                    <div class="fxDisplay fxDirRow listItem" v-for="item in stockedCartItems" :key="item.ItemID">
                    
                      <div class="fxDisplay fxDirRow listItem--left">
                        <img class="listItemImg" :src="cartItemsImgs[item.ItemID] !== 'n/a' ? cartItemsImgs[item.ItemID]  : 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'"/>
                        <h3 class="listItemText" style="max-height: 80px; overflow: hidden;">{{item.Title}}</h3>
                      </div>
                      
                      <div class="fxDisplay fxDirCol listItem--right">
                          <h4 class="listItemText">€{{(item.Price * item.Quantity).toFixed(2)}}</h4>
                          <div class="fxDisplay fxDirRow" style="gap: 5px;">
                            <div class="fxDisplay fxDirRow quantityBtnsContainer">
                              <v-icon icon="mdi-minus-circle-outline" class="quantityBtns" :class="{'quantityBtns--disabled' : !(item.Quantity > 1)}" 
                                size="20" @click="decrementCartItemQuantity(item.ItemID, item.Quantity)">
                              </v-icon>
                              <span class="listItemText quantityText">{{item.Quantity}}</span>
                              <v-icon icon="mdi-plus-circle-outline" class="quantityBtns" :class="{'quantityBtns--disabled' : !(item.Quantity < item.AvailableQuantity)}" 
                                size="20" @click="incrementCartItemQuantity(item.ItemID, item.Quantity)">
                              </v-icon>
                            </div>
                            <div class="fxDisplay fxDirRow trashBtnContainer sideCartIgnore" @click="removeItemFromCart(item.ItemID)">
                              <Vue3Lottie class="quantityBtns"
                                @mouseover="$refs['trashAnim1' + item.ItemID][0].play()"
                                @mouseleave="$refs['trashAnim1' + item.ItemID][0].stop()"
                                :ref="'trashAnim1' + item.ItemID"
                                :animationData="trashAnim"
                                width="20px" height="20px"
                                :loop="true"
                                :speed="0.5"
                                :autoPlay="false"/>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                </div>
                <!-- Dropped Items -->
                <div v-if="!loading && droppedCartItems.length > 0" style="width:100%">
                <!-- <h4 class="deliveryText">Delivery 4+ days:</h4> -->
                <div class="fxDisplay fxDirCol itemsContainer">
                    <div class="fxDisplay fxDirRow listItem" v-for="item in droppedCartItems" :key="item.ItemID">
                      <div class="fxDisplay fxDirRow listItem--left">
                        <img class="listItemImg" :src="cartItemsImgs[item.ItemID] !== 'n/a' ? cartItemsImgs[item.ItemID]  : 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'"/>
                        <h3 class="listItemText" style="max-height: 80px; overflow: hidden;">{{item.Title}}</h3>
                      </div>
                      
                      <div class="fxDisplay fxDirCol listItem--right">
                          <h4 class="listItemText">€{{(item.Price * item.Quantity).toFixed(2)}}</h4>
                          <div class="fxDisplay fxDirRow" style="gap: 5px;">
                            <div class="fxDisplay fxDirRow quantityBtnsContainer">
                              <v-icon icon="mdi-minus-circle-outline" class="quantityBtns" :class="{'quantityBtns--disabled' : !(item.Quantity > 1)}" 
                                size="20" @click="decrementCartItemQuantity(item.ItemID, item.Quantity)">
                              </v-icon>
                              <span class="listItemText quantityText">{{item.Quantity}}</span>
                              <v-icon icon="mdi-plus-circle-outline" class="quantityBtns" :class="{'quantityBtns--disabled' : !(item.Quantity < item.AvailableQuantity)}" 
                                size="20" @click="incrementCartItemQuantity(item.ItemID, item.Quantity)">
                              </v-icon>
                            </div>
                            <div class="fxDisplay fxDirRow trashBtnContainer sideCartIgnore">
                              <Vue3Lottie class="quantityBtns"
                                @click="removeItemFromCart(item.ItemID)"
                                @mouseover="$refs['trashAnim2' + item.ItemID][0].play()"
                                @mouseleave="$refs['trashAnim2' + item.ItemID][0].stop()"
                                :ref="'trashAnim2' + item.ItemID"
                                :animationData="trashAnim"
                                width="20px" height="20px"
                                :loop="true"
                                :speed="0.5"
                                :autoPlay="false"/>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                </div>
                <div v-if="!loading">
                <h2 v-if="stockedCartItems.length === 0 && droppedCartItems.length === 0">No items in your cart</h2>
                </div>
                
            </div>  
        
            <!-- Cart summary -->
            <div class="fxDisplay fxDirCol cartSummaryContainer">
                
                <div class="fxDisplay fxDirRow cartSummaryRow">
                  <span class="cartSummaryHeader">sub-total</span>
                  <span class="cartSummarySubHeader">€{{ totalPrice }}</span>
                </div>
                <h5 style="text-align: start;">Shipping, discount codes calculated at checkout</h5>
                
                <div class="fxDisplay fxDirRow" style="gap:16px; flex-wrap: wrap; width: 100%; justify-content: center;">
                  <v-btn variant="outlined" class="sideCartActionBtn sideCartActionBtn--secondary" @click="$emit('closeSideCart')">continue shopping</v-btn>
                  <v-btn variant="outlined" class="sideCartActionBtn" @click="viewCartBtnClicked" 
                    :disabled="stockedCartItems.length === 0 && droppedCartItems.length === 0">
                    view cart
                  </v-btn>
                </div>
            </div>
        </div>
    </Transition>
  </template>
    
  <script>
  // @ is an alias to /src
  // import $ from 'jquery';
  import axios from 'axios';
  import _ from 'lodash';
  import { baseURL } from '../envconfig.js';
  import trashAnim from '@/assets/imgs/system-regular-39-trash.json';
  
  export default {
    name: 'SideCart',
    components: {
    },
    props: {
        visible: {
          type: Boolean,
          required: true,
          default: false
        }
    },
    data () {
      return {
        loading : false,
        errorMsg : "",
        cartItemsImgs: [],

        //animations
        trashAnim: trashAnim,
      }
    },
    methods : {
        viewCartBtnClicked : function ()
        {   
          this.$emit("closeSideCart");
          this.$router.push('/cart');
        },
        clearCart : function ()
        {
            this.$store.dispatch('clearCart'); //we clear cart since order was successful 
            this.cartItems = this.$store.getters.getCart; //refreshing cart objects. (stockerCartItems and droppedCartItems)
        },
        decrementCartItemQuantity: function (itemID, CurrentQuantity)
        {
            let updatePayload = { ItemID: itemID, Quantity: (CurrentQuantity - 1) };
            this.$store.dispatch('updateQuantityOfItem', updatePayload).then(
            function (success){
                if( success )
                {
                  // console.log(`Successfully updated ${JSON.stringify(updatePayload)} in your cart`);
                }
                else{
                  throw Error("unable to update cart quanitity");
                }
            }
            );
        },
        incrementCartItemQuantity: function (itemID, CurrentQuantity)
        {
            let updatePayload = { ItemID: itemID, Quantity: (CurrentQuantity + 1) };
            this.$store.dispatch('updateQuantityOfItem', updatePayload).then(
            function (success){
                if( success )
                {
                  // console.log(`Successfully updated ${JSON.stringify(updatePayload)} in your cart`);
                }
                else{
                  throw Error("unable to update cart quanitity");
                }
            }
            );
        },
        removeItemFromCart: function ( itemID )
        {
            
            this.$store.dispatch('removeFromCart', itemID).then(
            function (success)
            {
                //show dialog.
                if( success )
                {
                  //console.log(`Successfully removed item the your cart`);
                }
                else
                {
                  throw Error("unable to update remove item from cart");
                }
            }.bind(this)
            );
        },
        reloadCartItems: function ( )
        {
    
            this.loading = true;
            const oldFullCartDetails = this.$store.getters.getCart; //will contain all item details so Title is also included. (needed for items which have run out of stock to still be able to get their title for the errormsg)
            const cartItemIDsAndLocalQuantities = this.$store.getters.getCartItemIDsAndQuantity; //gets itemIDs and Quantities in array of objects for store refreshCartArrayValuesFromItemIDs action.
    
            this.$store.dispatch('refreshCartArrayValuesFromItemIDs', cartItemIDsAndLocalQuantities)
            .then( function() 
            {
            this.loading = false; //once refreshcart is completed, our computed "cartItems" will get the new changes from the getCart getter and all the changed will take palce.
            const updatedCartQuantitiesWithFullDetails = _.cloneDeep(this.$store.getters.getCart);
            for( let x = 0; x < oldFullCartDetails.length; x++ )
            {
                const currentItem = oldFullCartDetails[x];
                
                if(currentItem.ItemID && currentItem.Quantity)
                {
                const updatedItem = updatedCartQuantitiesWithFullDetails.find( item => item.ItemID === currentItem.ItemID);
                if(updatedItem && updatedItem.Quantity === currentItem.Quantity) //no change happened to this item's quantity.
                {
                    // this.errorMsg = this.errorMsg + `Item ${storeItem.itemID} remained unchanged`; 
                }
                else if (updatedItem && updatedItem.Quantity < currentItem.Quantity) //available quantity is less than the user had selected
                {
                    this.errorMsg = this.errorMsg + `Warning: Stock for ${currentItem.Title} has reduced, new selected quantity ${updatedItem.Quantity}.\n`; 
                }
                else //available quantity for this item was 0 so it was removed from the return which populated the store.
                {
                    this.errorMsg = this.errorMsg + `Warning: ${currentItem.Title} is now out of stock sorry\n`; 
                }
                }
            }
            }.bind(this));
        },
        getCartItemsImgs: function ()
        {
            //will get all the imgs for the cart items.
            this.$nextTick(function ()
            {
            const itemIDsArray = this.$store.getters.getCart.map(item => Number(item.ItemID)); //getting just the itemIDs. Was using cartItems computed property, but it wasnt being updated in time, so instead we use the getter directly.
            const promise = axios.post(`${baseURL}/items/itemImage`,{ItemIDs : itemIDsArray});
            promise.then( function (res)
            {
                if(res && res.status === 200)
                {
                this.cartItemsImgs = res.data;
                }
                else
                {
                //unable to get images.
                //Creating mock data return object with all images value set to n/a to trigger our default img.
                const itemImgsObject = {};
                itemIDsArray.forEach(item => {
                    itemImgsObject[item] = "n/a";
                });
                this.cartItemsImgs = itemImgsObject;
                throw Error("Unable to get images");
                }
            }.bind(this));
            promise.catch(function () {
                //unable to get all the images for the cart items.
                //Creating mock data return object with all images value set to n/a to trigger our default img.
                const itemImgsObject = {};
                itemIDsArray.forEach(item => {
                itemImgsObject[item] = "n/a";
                });
                this.cartItemsImgs = itemImgsObject;
                throw Error("Unable to get images");
            }.bind(this));
            }.bind(this));
            
        }
    },
    computed : {
      progressBarColour: function ()
      {
        if( this.percentageTillFreeDelivery < 100 )
        {
          return "#104112";
        }
        else
        {
          return "#2a882e";
        }
      },
      remainingValueTillFreeDelivery : function ()
      {
        if( this.totalPrice ) 
        {
          if( 80 - this.totalPrice > 0 )
          {
            return (80-this.totalPrice).toFixed(2);
          }
          else
          {
            return 0;
          }
        }
        else
        {
          return null;
        }
      },
      percentageTillFreeDelivery: function ()
      {
        if( this.totalPrice ) 
        {
          if (80 - this.remainingValueTillFreeDelivery < 80)
          {
            return (((80 - this.remainingValueTillFreeDelivery)/80)* 100).toFixed(2);
          }
          else
          {
            return 100;
          }
        }
        else
        {
          return 0;
        }
      },
      cartItems : function () 
      {
        return this.$store.getters.getCart;
      },
      stockedCartItems : function ()
      {
        let stockedCartItems = [];
        for( let x = 0; x < this.cartItems.length; x++ )
        {
          if(this.cartItems[x].StockedItem === 1)
          {
            stockedCartItems.push(this.cartItems[x]);
          }
        }
        return stockedCartItems;
      },
      droppedCartItems : function ()
      {
        let droppedCartItems = [];
        for( let x = 0; x < this.cartItems.length; x++ )
        {
          if(this.cartItems[x].StockedItem === 0)
          {
            droppedCartItems.push(this.cartItems[x]);
          }
        }
        return droppedCartItems;
      },
      totalPrice : function ()
      {
        let total = 0;
        for( let x = 0; x < this.cartItems.length; x++ )
        {
          total = total + (this.cartItems[x].Price * this.cartItems[x].Quantity);
        }
        return total.toFixed(2);
      }
    },
    watch: {
      cartItems: function (newValue) //we reload the cart images every time the cart items gets changed.
      {
        if(newValue.length > 0 )
        {
          this.getCartItemsImgs();
        }
      },
    },
    mounted () {
      /*Reloading cart data from server. This will return all the item details including available quantity.
      which will update the cart item's quantities if they exceed the available. */
      this.reloadCartItems();
    }
  }
</script>
<style lang="scss" scoped>
  
  .cartContainer
  {
    position: fixed;
    //top: 120px; //offset by default (desktop) nav bar height.
    right: 0px;
    background: white;
    max-width: 528px;
    width: 100%;
    // height: calc(100vh - 120px);
    height: 100vh;
    justify-content: start;
    padding: 24px 24px;
    z-index: 99;
    gap:16px;
  }
  


  .cartItemsContainer{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    // row-gap: 40px; //this is 40 when we show the delivery text. For now we commented the delivery text for side cart so we need a smaller gap.
    row-gap: 16px;
    max-width: 528px;
    text-align: start;
    height: 100%;
    overflow-y: auto;
  }
  
  .itemsContainer
  {
    gap:16px;
  }

  .cartHeaderContainer
  {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .progressDeliveryContainer
  {
    align-items: center; 
    gap:8px;
    width: 100%;
  }

  .progressbarContainer {
    width: 100%;
  }

  .freeDeliveryText
  {
    color: $dirt;
    font-size: 12px;
  }

  @media (max-width: 730px) //mobile mode
  {
    .cartContainer
    {
      height: 100vh;
      padding: 8px;
    }

  }
  
  
  .listItem {
    padding: 0px 16px 0px 0px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    background-color: $white;
  }
  
  .listItem--left 
  {
    align-items: center;
    gap: 16px;
  }
  
  .listItem--right
  {
    gap: 8px;
    align-items: end;
  }
  
  .listItemImg
  {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    object-fit: cover;
  }

  .listItemText
  {
    color: $forest;
    font-family: Hind-Bold, sans-serif;
    font-size: 14px;
    font-weight: bold;
    user-select: none;
  }
  
  .quantityBtnsContainer
  {
    gap: 12px;
    padding: 4px 8px;
    border-radius: 50px;
    width: 94px;
    height: 32px;
    background: $ivory;
    align-items: center;
  }
  
  .quantityText
  {
    color: $forest;
    width: 8px;
  }
  
  .trashBtnContainer
  {
    padding: 6px 7px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: $ivory;
    align-items: center;
    cursor: pointer;
  }
  
  .quantityBtns
  {
    color: $dirt;
  }
  
  .quantityBtns--disabled
  {
    opacity: 0.6;
    pointer-events: none;
  }
  
  .errorMsgContainer
  { 
    gap: 5px;
    align-items: start;
  }
  
  .deliveryText
  {
    font-family: Hind-Bold, sans-serif;
    font-weight: bold;
    color: $forest;
  }
  
  .cartSummaryContainer
  {
    width: 100%;
    height: 201px;
    margin-bottom: 30px;
    gap: 16px;
    // background-color: $white;
    align-items: start;
  }
  
  .cartSummaryRow
  {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .cartSummaryHeader
  {
    font-family: Hind-Bold, sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
  }
  
  .cartSummarySubHeader
  {
    font-family: Hind, sans-serif;
    font-size: 24px;
    line-height: 100%;
  }
  
  .sideCartActionBtn 
  {
    background: $leaf;
    color: $white;
    border-radius: 50px;
    text-transform: none;
    font-family: new-order, sans-serif;
    font-weight: normal;
    font-size: 16px;
    // padding: 15px 40px;
    height: 40px;
    width: 100%;
    max-width: 232px;
    align-self: center
  }
  
  .sideCartActionBtn.v-btn--density-default {
    height: 42px
  }

  .sideCartActionBtn--secondary
  {
    background: $ivory;
    color: $forest;
  }
  
  @media (max-width: 900px) {
    .cartSummaryContainer
    {
      margin-top: 16px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 80px;
      gap: 8px;
      width: 100%;
    }
    
  }

.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(500px);
  opacity: 0;
}

:deep(button){
  padding-top:3px;
}
</style>