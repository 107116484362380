import { createRouter, createWebHistory } from 'vue-router'
import UnauthorisedScreen from '../screens/UnauthorisedScreen.vue'

const HomeScreen = () => import(/* webpackChunkName: "home" */ '../screens/HomeScreen.vue');
const ShopScreen = () => import(/* webpackChunkName: "shop" */ '../screens/ShopScreen.vue');
const ItemScreen = () => import(/* webpackChunkName: "item" */ '../screens/ItemScreen.vue');
const CartScreen = () => import(/* webpackChunkName: "cart" */ '../screens/CartScreen.vue');
const LoginScreen = () => import(/* webpackChunkName: "login" */ '../screens/LoginScreen.vue');
const SignUpScreen = () => import(/* webpackChunkName: "signup" */ '../screens/SignUpScreen.vue');
const ActivateScreen = () => import(/* webpackChunkName: "activate" */ '../screens/ActivateScreen.vue');
const ResetScreen = () => import(/* webpackChunkName: "reset" */ '../screens/ResetScreen.vue');
const ForgotPasswordScreen = () => import(/* webpackChunkName: "forgotpassword" */ '../screens/ForgotPasswordScreen.vue');
const AccountScreen = () => import(/* webpackChunkName: "account" */ '../screens/AccountScreen.vue');
const BlogHomeScreen = () => import(/* webpackChunkName: "bloghome" */ '../screens/BlogHomeScreen.vue');
const BlogScreen = () => import(/* webpackChunkName: "blog" */ '../screens/BlogScreen.vue');
// const FAQScreen = () => import(/* webpackChunkName: "faq" */ '../screens/FAQScreen.vue');
const GoogleCallBack = () => import(/* webpackChunkName: "googlecallback" */ '../screens/GoogleCallBack.vue');

const SuccessScreen = () => import(/* webpackChunkName: "success" */ '../screens/SuccessScreen.vue');
const FailureScreen = () => import(/* webpackChunkName: "failure" */ '../screens/FailureScreen.vue');

//admin screens
const PortalScreen = () => import(/* webpackChunkName: "portal" */ '../backendScreens/PortalScreen.vue');
const ItemAdminScreen = () => import(/* webpackChunkName: "itemadmin" */ '../backendScreens/ItemAdminScreen.vue');
const OrdersAdminScreen = () => import(/* webpackChunkName: "ordersadmin" */ '../backendScreens/OrdersAdminScreen.vue');
const OrderDetailsScreen = () => import(/* webpackChunkName: "orderdetails" */ '../backendScreens/OrderDetailsScreen.vue');
const ItemEditScreen = () => import(/* webpackChunkName: "itemedit" */ '../backendScreens/ItemEditScreen.vue');
const InventoryEditScreen = () => import(/* webpackChunkName: "inventoryedit" */ '../backendScreens/InventoryEditScreen.vue');

//static screens (not actually static but just info screens)
const CookiePolicyScreen = () => import(/* webpackChunkName: "cookiepolicy" */ '@/screens/static/CookiePolicyScreen.vue');
const DeliveryReturnsScreen = () => import(/* webpackChunkName: "deliveryreturns" */ '@/screens/static/DeliveryReturnsScreen.vue');
const PrivacyPolicyScreen = () => import(/* webpackChunkName: "privacypolicy" */ '@/screens/static/PrivacyPolicyScreen.vue');
const TermsOfServiceScreen = () => import(/* webpackChunkName: "termsofservice" */ '@/screens/static/TermsOfServiceScreen.vue');
const GGguaranteeScreen = () => import(/* webpackChunkName: "ggguarantee" */ '@/screens/static/GGguaranteeScreen.vue');


//---- utility functions ----
function sanitizeItemSearchParam(searchParam) { //will make sure the category or tag passed is valid, and identifies what it is.
  const validCategories = [
    'natural minerals', 'organic fertilizers', 'pesticides and fungicides', 'soil and substrates', 'fabric pots',
    'organic plant boosters', 'beneficial microbes', 'water quality', 'custom mix and consultation',
    'all'
  ];
  const validTags = [ //these are now deprecated, since we removed tags from the UI.
    'guru', 'fourplant'
  ];

  if (searchParam !== undefined && validCategories.includes(searchParam.toLowerCase())) {
    return {
      type: "category",
      value: searchParam.toLowerCase()
    }
  } 
  else if (searchParam !== undefined && validTags.includes(searchParam.toLowerCase())) {
    return {
      type: "tag",
      value: searchParam.toLowerCase()
    }
  }
  else if (searchParam !== undefined )
  {
    return {
      type: "search",
      value: searchParam
    }
  }
  else 
  {
    return null;
  }
}
//---- END utility functions ----

// import $ from 'jquery';
import { store } from '../stores/store.js'; //vueX store
const routes = [
  {
    path: '/unauthorised',
    name: 'unauthorised',
    component: UnauthorisedScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a

    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'home'); //setting active page.
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopScreen,
    props: (route) => ({
      searchParam: sanitizeItemSearchParam('all'), //route.params.searchParam
      offset: route.params.offset ? parseInt(route.params.offset) : 1, // Convert offset
    }),
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'shop'); //setting active page.
    }
  },
  {
    path: '/shop/:searchParam/:offset?',
    name: 'shopWithSearchParam',
    component: ShopScreen,
    props: (route) => ({
      ...route.params,
      searchParam: sanitizeItemSearchParam(route.params.searchParam),
      offset: route.params.offset ? parseInt(route.params.offset) : 1, // Convert offset
    }),
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'shop'); //setting active page.
    }
  },

  {
    path: '/item/:itemTitle',
    name: 'item',
    component: ItemScreen,
    props: (route) => ({
      itemTitle: route.params.itemTitle,
    }),
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
    },
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
    }
  },
  {  
    path: '/success',
    name: 'successScreen',
    component: SuccessScreen,
    props: () => ({
      screenVariant: "success", //changes page title
    }),
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
    }
  },
  {//same route as above but with "orderdetails" as url for when user clicks view order in account page.
    path: '/orderdetails',
    name: 'orderdetailsScreen',
    component: SuccessScreen,
    props: () => ({
      screenVariant: "orderdetails", //changes page title
    }),
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
    }
  },
  {  
    path: '/failedOrder',
    name: 'failureScreen',
    component: FailureScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../screens/AboutScreen.vue'),
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'about us'); //setting active page.
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'login'); //setting active page.

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;

      if(isRefreshed) //case that the user refreshed the page, store loggedIn value is reset so we need to re-check from the server if the user is actually logged in.
      {
        const promise = store.dispatch('isUserLoggedInSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (!store.getters.getIsLoggedIn) {
            // if user is NOT loggedIn then we allow access to login page.
            next();
          }
          else // if user is logged in then we dont allow access to login page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (!store.getters.getIsLoggedIn) {
          // if user is NOT loggedIn then we allow access to login page.
          next()
        } 
        else {
          // if user is logged in then we dont allow access to login page.
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'sign up'); //setting active page.

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;

      if(isRefreshed) //case that the user refreshed the page, store loggedIn value is reset so we need to re-check from the server if the user is actually logged in.
      {
        const promise = store.dispatch('isUserLoggedInSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (!store.getters.getIsLoggedIn) {
            // if user is NOT loggedIn then we allow access to signup page.
            next();
          }
          else // if user is logged in then we dont allow access to signup page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (!store.getters.getIsLoggedIn) {
          // if user is NOT loggedIn then we allow access to signup page.
          next()
        } 
        else {
          // if user is logged in then we dont allow access to signup page.
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/activate',
    name: 'activate',
    component: ActivateScreen,
    props: (route) => ({
      username: route.query.username,
      codeAutoFill: route.query.code || null, // code is optional
    }),
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
  
      // Check if the user is authenticated
      const isRefreshed = from.name === undefined;
  
      if (!to.query.username) {
        // If username is missing
        next('/unauthorised');
      } 
      else if (isRefreshed) {
        const promise = store.dispatch('isUserLoggedInSync');
  
        promise.then(() => {
          if (!store.getters.getIsLoggedIn) {
            // if user is NOT loggedIn then we allow access to activate page.
            next();
          } else {
            next('/unauthorised');
          }
        });
  
        promise.catch(() => {
          next('/unauthorised');
        });
      } 
      else 
      {
        if (!store.getters.getIsLoggedIn) {
          // if user is NOT loggedIn then we allow access to activate page.
          next();
        } else {
          next('/unauthorised');
        }
      }
    },
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: ForgotPasswordScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a

      // Check if the user is authenticated
      const isRefreshed = from.name === undefined;
      
      if (isRefreshed) {
        const promise = store.dispatch('isUserLoggedInSync');
  
        promise.then(() => {
          if (!store.getters.getIsLoggedIn) {
            // if user is NOT loggedIn then we allow access to activate page.
            next();
          } else {
            next('/unauthorised');
          }
        });
  
        promise.catch(() => {
          next('/unauthorised');
        });
      } 
      else 
      {
        if (!store.getters.getIsLoggedIn) {
          // if user is NOT loggedIn then we allow access to activate page.
          next();
        } else {
          next('/unauthorised');
        }
      }
    },
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetScreen,
    props: (route) => ({
      username: route.query.username,
      code: route.query.token, // token is required.
    }),
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
  
      // Check if the user is authenticated
      const isRefreshed = from.name === undefined;
  
      if (!to.query.username || !to.query.token) {
        // If username is missing or token is missing, we dont allow access
        next('/unauthorised');
      } 
      else if (isRefreshed) {
        const promise = store.dispatch('isUserLoggedInSync');
  
        promise.then(() => {
          if (!store.getters.getIsLoggedIn) {
            // if user is NOT loggedIn then we allow access to activate page.
            next();
          } else {
            next('/unauthorised');
          }
        });
  
        promise.catch(() => {
          next('/unauthorised');
        });
      } 
      else 
      {
        if (!store.getters.getIsLoggedIn) {
          // if user is NOT loggedIn then we allow access to activate page.
          next();
        } else {
          next('/unauthorised');
        }
      }
    },
  },
  {
    path: '/account',
    name: 'account',
    component: AccountScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'account'); //not a link in navbar so we set to n/a

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;

      if(isRefreshed) //case that the user refreshed the page, store loggedIn value is reset so we need to re-check from the server if the user is actually logged in.
      {
        const promise = store.dispatch('isUserLoggedInSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (store.getters.getIsLoggedIn) {
            // if user is loggedIn then we allow access to account page.
            next();
          }
          else // if user is not logged in then we dont allow access to account page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (store.getters.getIsLoggedIn) {
          // if user is loggedIn then we allow access to account page.
          next()
        } 
        else {
          // if user is not logged in then we dont allow access to account page.
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: BlogHomeScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'blogs'); //setting active page.
    }
  },
  {
    path: '/blogs/:title',
    name: 'blog',
    component: BlogScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'blogs'); //setting active page.
    }
  },
  // { removed to avoid indexing since we dont use screen.
  //   path: '/frequently-asked-questions',
  //   name: 'FAQ',
  //   component: FAQScreen,
  //   beforeEnter: () => {
  //     store.dispatch('updateValidSideCartScreenState', true);
  //     store.dispatch('updateCurrentNavBarPageSelected', 'FAQs'); //setting active page.
  //   }
  // },
  {
    path: '/auth/google/callback',
    name: 'GoogleCallBack',
    component: GoogleCallBack,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
    }
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicyScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //setting active page.
    }
  },
  {
    path: '/delivery-returns',
    name: 'DeliveryAndReturns',
    component: DeliveryReturnsScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'delivery-returns'); //setting active page.
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'privacy-policy'); //setting active page.
    }
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfServiceScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'terms-of-service'); //setting active page.
    }
  },
  {
    path: '/garden-guru-guarantee',
    name: 'GGguarantee',
    component: GGguaranteeScreen,
    beforeEnter: () => {
      store.dispatch('updateValidSideCartScreenState', true);
      store.dispatch('updateCurrentNavBarPageSelected', 'garden-guru-guarantee'); //setting active page.
    }
  },
  



  //---------------------mod routes.-------------------------
  {
    path: '/portal',
    name: 'PortalScreen',
    component: PortalScreen,
    // beforeRouteEnter: (to, from, next)
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;
      
      if(isRefreshed) //case that the user refreshed the page, store mod value is reset so we need to re-check from the server if the user is a mod and logged in.
      {
        const promise = store.dispatch('isUserModSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
            // if user is loggedIn and is MOD then we allow access to login page.
            next();
          }
          else // if user is not logged in and not mod then we dont allow access to portal page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
          // if user is loggedIn and is MOD then we allow access to login page.
          next()
        }
        else // if user is not logged in and not mod then we dont allow access to portal page.
        {
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/itemAdmin',
    name: 'ItemAdminScreen',
    component: ItemAdminScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;
      
      if(isRefreshed) //case that the user refreshed the page, store mod value is reset so we need to re-check from the server if the user is a mod and logged in.
      {
        const promise = store.dispatch('isUserModSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
            // if user is loggedIn and is MOD then we allow access to login page.
            next();
          }
          else // if user is not logged in and not mod then we dont allow access to portal page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
          // if user is loggedIn and is MOD then we allow access to login page.
          next()
        }
        else // if user is not logged in and not mod then we dont allow access to portal page.
        {
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/itemEdit/:id',
    name: 'ItemEdit',
    component: ItemEditScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;
      
      if(isRefreshed) //case that the user refreshed the page, store mod value is reset so we need to re-check from the server if the user is a mod and logged in.
      {
        const promise = store.dispatch('isUserModSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
            // if user is loggedIn and is MOD then we allow access to login page.
            next();
          }
          else // if user is not logged in and not mod then we dont allow access to portal page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
          // if user is loggedIn and is MOD then we allow access to login page.
          next()
        }
        else // if user is not logged in and not mod then we dont allow access to portal page.
        {
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/inventoryEdit/:id',
    name: 'InventoryEdit',
    component: InventoryEditScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;
      
      if(isRefreshed) //case that the user refreshed the page, store mod value is reset so we need to re-check from the server if the user is a mod and logged in.
      {
        const promise = store.dispatch('isUserModSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
            // if user is loggedIn and is MOD then we allow access to login page.
            next();
          }
          else // if user is not logged in and not mod then we dont allow access to portal page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
          // if user is loggedIn and is MOD then we allow access to login page.
          next()
        }
        else // if user is not logged in and not mod then we dont allow access to portal page.
        {
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/ordersAdmin',
    name: 'OrdersAdminScreen',
    component: OrdersAdminScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a

      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;
      
      if(isRefreshed) //case that the user refreshed the page, store mod value is reset so we need to re-check from the server if the user is a mod and logged in.
      {
        const promise = store.dispatch('isUserModSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
            // if user is loggedIn and is MOD then we allow access to login page.
            next();
          }
          else // if user is not logged in and not mod then we dont allow access to portal page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
          // if user is loggedIn and is MOD then we allow access to login page.
          next()
        }
        else // if user is not logged in and not mod then we dont allow access to portal page.
        {
          next('/unauthorised')
        }
      }
    }
  },
  {
    path: '/orderDetails/:id',
    name: 'OrdersDetailsScreen',
    component: OrderDetailsScreen,
    beforeEnter: (to, from, next) => {
      store.dispatch('updateValidSideCartScreenState', false);
      store.dispatch('updateCurrentNavBarPageSelected', 'n/a'); //not a link in navbar so we set to n/a
      
      //Check if the user is authenticated
      const isRefreshed = from.name === undefined;
      
      if(isRefreshed) //case that the user refreshed the page, store mod value is reset so we need to re-check from the server if the user is a mod and logged in.
      {
        const promise = store.dispatch('isUserModSync'); //to do this, we use the Sync method which will return a promise and allow us to wait for the result.
        promise.then(function ()
        {
          if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
            // if user is loggedIn and is MOD then we allow access to login page.
            next();
          }
          else // if user is not logged in and not mod then we dont allow access to portal page.
          {
            next('/unauthorised')
          }
        }.bind(this));
        promise.catch(function ()
        {
          next('/unauthorised');
        }.bind(this));
      }
      else
      {
        if (store.getters.getIsLoggedIn && store.getters.getIsMod) {
          // if user is loggedIn and is MOD then we allow access to login page.
          next()
        }
        else // if user is not logged in and not mod then we dont allow access to portal page.
        {
          next('/unauthorised')
        }
      }
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) { //from, savedPosition
    // Check if the route has a hash (anchor link)
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      //Checking is the page being travelled from is the same as the page being travelled two (item pages) in which case we do not reset scroll.
      if ( (from.name === to.name) && to.name === 'item' && !to.query.scrollToTop) { //to.meta.scrollToTop
        // Preserve the current scroll position
        return savedPosition;
      } 
      else {
        // Reset scroll position to top for all other routes
        return { top: 0 };
      }
    }
  },

});

router.afterEach((to) => {
  if (store.getters.getCookieConsentState) { // Check if consent is given
    if (window.fbq) {
      window.fbq('track', 'PageView', {
        page_path: to.fullPath,
        page_name: to.name
      });
    } 
    else {
      console.warn('Facebook Pixel not loaded');
    }
  }
});

export default router;