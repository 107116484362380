<template>
  <div>
    <h1>Oops seems like you stumbled upon a restricted page!</h1>
    <h2>Please ensure you are logged in.</h2>
    <h3><router-link style="color: black" to="/">Back to Home</router-link></h3>
  </div>
</template>
<script>
import { NoIndexMixin } from '../mixins/noIndexMetaTagMixin';

export default {
    name: 'UnauthorisedScreen',
    mixins: [NoIndexMixin],
    mounted() {
      this.$store.dispatch('isUserLoggedIn');
      this.$store.dispatch('isUserMod');
    }

}
</script>