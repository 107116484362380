export const metaTrackMixin = {
    //mixin to facilitate tracking custom events and keeping them inline template.
    methods: {
        trackEventCustom(eventName, eventPayload) {
            if (this.$store.getters.getCookieConsentState)
            {
                if( window.fbq )
                {
                    window.fbq('trackCustom', eventName, eventPayload);
                }    
            }
        },
        trackEventStandard(eventName, eventPayload) {
            if (this.$store.getters.getCookieConsentState)
            {
                if( window.fbq )
                {
                    window.fbq('track', eventName, eventPayload);
                }    
            }
        },
    }
}