<template>
    <div class="fxDisplay fxDirCol" style="width: 100%; justify-content: center;">
        <div class="fxDisplay fxDirRow footerContainer footerContainer--row1" >
            <div class="fxDisplay fxDirCol sector firstBox">
                <div class="navContainer" style="width: 68px;">
                    <router-link class="navLink" to="/">home</router-link>
                </div>
                <div class="navContainer" style="width: 68px;">
                    <router-link class="navLink" to="/shop">shop</router-link>
                </div>
                <div class="navContainer" style="width: 68px;">
                    <router-link class="navLink" to="/blogs">learn</router-link>
                </div>
                <div class="navContainer" style="width: 68px;">
                    <router-link class="navLink" to="/about">mission</router-link>
                </div>
            </div>
            <div class="fxDisplay fxDirCol sector secondBox">
                <div class="navContainer" style="width: 145px;">
                    <router-link class="navLink" to="/delivery-returns">delivery & returns</router-link>
                </div>
                <div class="navContainer" style="width: 145px;">
                    <router-link class="navLink" to="/garden-guru-guarantee">GG guarantee</router-link> 
                </div>
                <div class="navContainer" style="width: 145px;">
                    <router-link class="navLink" to="/terms-of-service">terms of service</router-link> 
                </div>
                <div class="navContainer" style="width: 145px;">
                    <router-link class="navLink" to="/privacy-policy">privacy policy</router-link>
                </div>
            </div>
            <div class="fxDisplay fxDirCol sector thirdBox thirdBox--initial">
                <h5>Garden Guru Ltd.</h5>
                <h5>+356 79009513</h5>
                <h5>grow@gardenguru.mt</h5>
            </div>
            <div class="fxDisplay fxDirCol sector fourthBox fourthBox--initial" style="justify-content: center;">
                <a href="https://www.facebook.com/gardengurumt" target="_blank" style="height:24px;">
                    <img src="../assets/imgs/home/social-facebook.svg" alt="Garden Guru on Facebook">
                </a>
                <a href="https://www.instagram.com/gardengurumt" target="_blank" style="height:24px;">
                    <img src="../assets/imgs/home/social-instagram.svg" alt="Garden Guru on Youtube">
                </a>
                <a href="https://www.youtube.com/@gardengurumt" target="_blank" style="height:24px;">
                    <img src="../assets/imgs/home/social-youtube.svg" alt="Garden Guru on Youtube">
                </a>
            </div>
            <div class="fxDisplay fxDirCol sector fifthBox fifthBox--initial">
                <div class="fxDisplay fxDirCol" style=" width: 100%; gap: 16px; align-items: start; justify-content: center; height: 100%;" >
                    <h5 style="font-weight: bold; font-family: 'Hind-Bold'">Subscribe to our monthly newsletter: The Grow Zone</h5>
                    <div class="fxDisplay fxDirRow newsLetterCTAContainer" >
                        <div style="width: 100%">
                            <v-text-field v-model="subscribeEmail" class="emailInput" placeholder="your email" variant="outlined" density="comfortable" single-line></v-text-field>
                        </div> 
                        <v-btn :disabled="!isValidSubscribeEmail" variant="outlined" class="signUpBtn" @click="subscribeToNewsLetter">subscribe</v-btn>
                    </div>
                </div>
            </div>
        </div>

        <!-- used for mobile -->
        <div class="fxDisplay fxDirRow footerContainer footerContainer--row2" >
            <div class="fxDisplay fxDirCol sector thirdBox thirdBox--secondary">
                <h5>Garden Guru Ltd.</h5>
                <h5>+356 79009513</h5>
                <h5>grow@gardenguru.mt</h5>
            </div>
            <div class="fxDisplay fxDirCol sector fourthBox fourthBox--secondary" style="justify-content: center;">
                <a href="https://www.facebook.com/gardengurumt" target="_blank" style="height:24px;">
                    <img src="../assets/imgs/home/social-facebook.svg" alt="Garden Guru on Facebook">
                </a>
                <a href="https://www.instagram.com/gardengurumt" target="_blank" style="height:24px;">
                    <img src="../assets/imgs/home/social-instagram.svg" alt="Garden Guru on Youtube">
                </a>
                <a href="https://www.youtube.com/@gardengurumt" target="_blank" style="height:24px;">
                    <img src="../assets/imgs/home/social-youtube.svg" alt="Garden Guru on Youtube">
                </a>
            </div>
        </div>

        <!-- used for mid and mobile -->
        <div class="fxDisplay fxDirRow footerContainer footerContainer--row3" >
            <div class="fxDisplay fxDirCol sector fifthBox fifthBox--secondary">
                <div class="fxDisplay fxDirCol" style=" width: 100%; gap: 16px; align-items: start; justify-content: center; height: 100%;" >
                    <h5 style="font-weight: bold; font-family: 'Hind-Bold'">Subscribe to our monthly newsletter: The Grow Zone</h5>
                    <div class="fxDisplay fxDirRow newsLetterCTAContainer" >
                        <div style="margin-right: 16px;">
                            <v-text-field v-model="subscribeEmail" class="emailInput" placeholder="your email" variant="outlined" density="comfortable" single-line></v-text-field>
                        </div> 
                        <v-btn :disabled="!isValidSubscribeEmail" variant="outlined" class="signUpBtn" @click="subscribeToNewsLetter">subscribe</v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery';
import { metaTrackMixin } from '@/mixins/metaTrackMixin.js';

export default {
    name: 'FooterBot',
    mixins: [metaTrackMixin],
    components: {
    },
    data () {
        return {
            subscribeEmail: "",
        }
    },
    props : {
    },
    methods : {
        subscribeToNewsLetter : function ()
        {      
        if(this.isValidSubscribeEmail)
        {
            window.sendinblue.identify(
                this.subscribeEmail
            );

            window.sendinblue.track(
                "subscribed-to-newsletter",
                {
                    email: this.subscribeEmail,
                    subscribeEmail: this.subscribeEmail
                }
            );

            this.subscribeEmail = ""; //reseting field
            this.$emit('showAlert', {
                type: "success",
                title: "Check your inbox to confirm your subscription",
                time: 5000,
                width: "330px"
            });

            //meta lead track
            this.trackEventStandard("Lead", {} );
        }
        else
        {
            this.$emit('showAlert', {
                type: "error",
                title: "Invalid email entered. Please enter a valid email!",
                time: 3000,
                width: "330px"
            });
        }
        
        },
    },
    computed : {
        isValidSubscribeEmail() {
            // Regular expression to check if the subscribeEmail format is valid
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(this.subscribeEmail);
        }
    },
    watch: {
        
    },
    mounted () {

    }
}
</script>

<style lang="scss" scoped>

.footerContainer
{
    flex-wrap: nowrap;
    border-top: $forest 1px solid;
    border-bottom: $forest 1px solid;
    height: fit-content;
    position: relative; 
    margin-top: 50px;
    padding-left: 120px;
    padding-right: 120px;
    width: 100%;
    justify-content: center;
    transition: padding-left 2s ease, padding-right 2s ease;
}

.sector
{
    gap: 8px;
    padding: 32px 32px;
    align-items: center;
    flex-grow: 1;
    border-right: 1px $forest solid;
    //border-bottom: 1px $forest solid;
    height: 216px;
}

.sector img
{
    width: 24px;
    height: 24px;
}
.navContainer {
  display: flex;
  justify-content: start;
}
.navLink
{
    font-size: 16px;
    font-weight: bold;
    font-family: Hind-Bold, sans-serif;
    color: $forest;
    text-decoration: none;
    text-align: start;
}


.newsLetterCTAContainer
{
    width: 100%; 
    gap: 16px;
}
.signUpBtn 
{
  background: $terracotta;
  color: $white;
  border-radius: 50px;
  text-transform: none;
  font-family: new-order, sans-serif;
  font-weight: normal;
  font-size: 16px;
//   padding: 15px 40px;
  height: 48px;
  width: 119px;
}

.signUpBtn.v-btn--density-default {
  height: 48px;
}

.firstBox
{
    max-width: 131px;
    height: 200px;
    border-left: 1px solid $forest
}

.secondBox
{
    max-width: 207px;
    height: 200px;
}

.thirdBox
{
    max-width: 253px;
    height: 200px;
    align-items: start;
    text-align: start;
}

.thirdBox--initial
{
    display: flex;
}

.thirdBox--secondary
{
    display: none;
}

.fourthBox
{
    gap: 24px; 
    max-width: 88px;
    height: 200px;
}

.fourthBox--initial
{
    display:flex;
}

.fourthBox--secondary
{
    display: none;
}

.fifthBox
{
    max-width: 519px; 
    align-self: center;
    height: 200px;
}

.fifthBox--initial
{
    display:flex;
}
.fifthBox--secondary
{
    display: none;
}

.footerContainer--row2
{
    display: none;
}

.footerContainer--row3
{
    display: none;
}

.emailInput
{
    color: $terracotta;
}

@media (max-width: 1350px) 
{
    .footerContainer
    {
        padding-left: 80px;
        padding-right: 80px;
        transition: padding-left 2s ease, padding-right 2s ease;
    }

}


@media (max-width: 1240px) 
{

    .footerContainer
    {
        flex-wrap: nowrap;
    }
    .footerContainer--row1
    {
        border-bottom: 0px;
    }

    .footerContainer--row3
    {
        display: flex;
        margin-top: 0px;
    }
    .fifthBox--initial
    {
        display: none;
    }

    .fifthBox--secondary
    {
        display: flex;
        height: 178px;
    }

    .firstBox
    {
        max-width: 155px;
    }

    .secondBox
    {
        max-width: 246px;
    }

    .thirdBox
    {
        max-width: 301px;
    }

    .fourthBox
    {
        gap: 24px; 
        max-width: 88px;
    }

    .fifthBox
    {
        max-width: 790px;
        width: 100%;
        border-left: 1px solid $forest;
    }

    .newsLetterCTAContainer
    {
        flex-wrap: wrap;
        gap: 0px;
        max-width: 487px;
    }

    // Targeting the text input for subsribe
    .newsLetterCTAContainer div 
    {
        width: 320px; 
    }
}

@media (max-width: 833px) 
{

    .footerContainer
    {
        padding-left: 0px;
        padding-right: 0px;
        transition: padding-left 2s ease, padding-right 2s ease;
    }
    .footerContainer--row1
    {
        border-bottom: 0px;
    }

    .footerContainer--row2
    {
        border-bottom: 0px;
        display: flex;
        margin-top: 0px;
    }

    .footerContainer--row3
    {
        display: flex;
        margin-top: 0px;
    }

    .firstBox
    {
        border-left: 0px;
        max-width: 100%;
        align-items: start;
    }

    .secondBox
    {
        border-right: 0px;
        max-width: 100%;
        align-items: start;
    }

    .thirdBox
    {
        max-width: 100%;
        align-items: start;
        justify-content: center;
    }

    .thirdBox--initial
    {
        display: none;
    }

    .thirdBox--secondary
    {
        display: flex;
    }

    .fourthBox
    {
        gap: 24px; 
        max-width: 88px;
        border-right: 0px;
    }

    .fourthBox--initial
    {
        display: none;
    }
    
    .fourthBox--secondary
    {
        display: flex;
    }

    .fifthBox
    {
        max-width: 100%;
        // width: %;
        border-left: 0px;
        border-right: 0px;
    }
    .fifthBox--initial
    {
        display: none;
    }

    .fifthBox--secondary
    {
        display: flex;
        height: 202px;
    }

    .newsLetterCTAContainer
    {
        flex-wrap: wrap;
        gap: 0px;
        max-width: 487px;
    }

    // Targeting the text input for subsribe
    .newsLetterCTAContainer div 
    {
        width: 100%;
        margin-right: 8px;
    }
    .signUpBtn
    {
        width: 98%;
    }
}

</style>