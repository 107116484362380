export const NoIndexMixin = {
    mounted() {
      this.setNoIndex();
    },
    methods: {
      setNoIndex() {
        // Check if the meta tag already exists
        let metaTag = document.head.querySelector('meta[name="robots"]');
        if (!metaTag) {
          // If the meta tag does not exist, create it
          metaTag = document.createElement('meta');
          metaTag.setAttribute('name', 'robots');
          document.head.appendChild(metaTag);
        }
        // Set content to 'noindex' to prevent search engine indexing
        metaTag.setAttribute('content', 'noindex');
      },
      removeNoIndex() {
        let metaTag = document.head.querySelector('meta[name="robots"]');
        if (metaTag) {
          // If the meta tag exists, remove it
          document.head.removeChild(metaTag);
        }
      }
    },
    beforeUnmount() {
      this.removeNoIndex();
    },
  };
  