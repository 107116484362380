<template>
    <div class="fxDisplay fxDirRow navContainer" :class="{'navContainer--blur': !burgerMenuOpen}">
        <div class="left">
            <div class="emptySpacer">
            </div>
            <router-link class="navLink ggLogoMain" to="/"><img src="@/assets/imgs/GG-logo.svg" alt="desktop garden guru logo"></router-link> 
            <router-link class="ggLogoMobile" to="/"><img src="@/assets/imgs/GG-logo-mobile.svg" alt="mobile garden guru logo"></router-link> 
        </div>
        <div class="rightDesktop">
            <router-link class="navLink" to="/" :class="{'navLink--selected': getNavBarPageSelected === 'home'}">home</router-link> 
            <router-link class="navLink" to="/shop" :class="{'navLink--selected': getNavBarPageSelected === 'shop'}">shop</router-link>
            <router-link class="navLink" to="/about" :class="{'navLink--selected': getNavBarPageSelected === 'about us'}">mission</router-link>
            <router-link class="navLink" to="/blogs" :class="{'navLink--selected': getNavBarPageSelected === 'blogs'}">learn</router-link>
            <div class="verticalLineBreak"></div>
            <div v-if="$store.getters.getIsMod">
                <router-link class="navLink" to="/portal">backHome</router-link> 
            </div>
            <div>
                <div class="topBarBtn accountModalIgnore" @click="$emit('changeAccountModalState')" @mouseover="$refs.accAnim1.play()" @mouseleave="$refs.accAnim1.stop()">
                    <Vue3Lottie class="topBarBtnDes accountModalIgnore"
                        ref="accAnim1"
                        :animationData="accAnim"
                        width="23px" height="21px"
                        :loop="true"
                        :speed="0.5"
                        :autoPlay="false"/>
                </div>
            </div>
            <!-- logged out account modal -->
            <Transition name="moon">
                <div v-if="accountModalOpen && !$store.getters.getIsLoggedIn" class="fxDisplay fxDirCol accountModal">
                    <h4 style="font-family: 'new-order', Hind, sans-serif; font-weight: 700;">Hello</h4>
                    <v-btn variant="outlined" class="primaryLoginBtn" style="align-self: center;" @click="accountModalClicked('login')">
                        login
                    </v-btn>
                    <v-btn variant="outlined" class="primaryLoginBtn secondaryLoginBtn" style="align-self: center;" @click="accountModalClicked('signup')">
                        sign up
                    </v-btn>
                </div>
            </Transition>
            
            <!-- logged in account modal -->
            <Transition name="moon" appear>
                <div v-if="accountModalOpen && $store.getters.getIsLoggedIn" class="fxDisplay fxDirCol accountModal">
                    <h4 style="font-family: 'new-order', Hind, sans-serif; font-weight: 700;">Hello</h4>
                    <v-btn variant="outlined" class="primaryLoginBtn" style="align-self: center;" @click="accountModalClicked('account')">
                        account
                    </v-btn>
                    <v-btn variant="outlined" class="primaryLoginBtn secondaryLoginBtn" style="align-self: center;" @click="logOut">
                        log out
                    </v-btn>
                </div>
            </Transition>
            <div class="verticalLineBreak"></div>
            <div class="cart">
                <v-badge :content="$store.getters.getNumberOfItemsInCart" color="success">
                    <div class="topBarBtn sideCartIgnore" @click="cartBtnClicked" @mouseover="$refs.cartAnim1.play()" @mouseleave="$refs.cartAnim1.stop()">
                        <Vue3Lottie class="topBarBtnDes sideCartIgnore"
                            ref="cartAnim1"
                            :animationData="cartAnim"
                            width="23px" height="21px"
                            :loop="true"
                            :speed="0.5"
                            :autoPlay="false"/>
                    </div>
                </v-badge>
            </div>
        </div>
        <div class="rightMobile">
            <div class="verticalLineBreak"></div>
            <div class="fxDisplay fxDirCol">
                <div class="topBarBtn" @click="this.$router.push('/shop')" @mouseover="$refs.shopAnim1.play()" @mouseleave="$refs.shopAnim1.stop()">
                    <Vue3Lottie class="topBarBtnDes accountModalIgnore"
                        @mouseover="$refs.shopAnim1.play()"
                        @mouseleave="$refs.shopAnim1.stop()"
                        ref="shopAnim1"
                        :animationData="shopAnim"
                        width="23px" height="21px"
                        :loop="true"
                        :speed="0.5"
                        :autoPlay="false"/>
                </div>
            </div>
            <!-- <div class="verticalLineBreak"></div>
            <div>
                <div class="topBarBtn accountModalIgnore" @click="$emit('changeAccountModalState')" @mouseover="$refs.accAnim2.play()" @mouseleave="$refs.accAnim2.stop()">
                    <Vue3Lottie class="topBarBtnDes accountModalIgnore"
                        @mouseover="$refs.accAnim2.play()"
                        @mouseleave="$refs.accAnim2.stop()"
                        ref="accAnim2"
                        :animationData="accAnim"
                        width="23px" height="21px"
                        :loop="true"
                        :speed="0.5"
                        :autoPlay="false"/>
                </div>
            </div>
            
            <Transition name="moon" appear>
                //logged out account modal
                <div v-if="accountModalOpen && !$store.getters.getIsLoggedIn" class="fxDisplay fxDirCol accountModal">
                    <h4 style="font-family: 'new-order', Hind, sans-serif; font-weight: 700;">Hello</h4>
                    <v-btn variant="outlined" class="primaryLoginBtn" style="align-self: center;" @click="accountModalClicked('login')">
                        login
                    </v-btn>
                    <v-btn variant="outlined" class="primaryLoginBtn secondaryLoginBtn" style="align-self: center;" @click="accountModalClicked('signup')">
                        sign up
                    </v-btn>
                </div>
            </Transition>

            <Transition name="moon" appear>
                //logged in account modal
                <div v-if="accountModalOpen && $store.getters.getIsLoggedIn" class="fxDisplay fxDirCol accountModal">
                    <h4 style="font-family: 'new-order', Hind, sans-serif; font-weight: 700;">Hello</h4>
                    <v-btn variant="outlined" class="primaryLoginBtn" style="align-self: center;" @click="accountModalClicked('account')">
                        account
                    </v-btn>
                    <v-btn variant="outlined" class="primaryLoginBtn secondaryLoginBtn" style="align-self: center;" @click="logOut">
                        log out
                    </v-btn>
                </div>
            </Transition> -->
            <div class="verticalLineBreak"></div>
            <div class="cart">
                <v-badge :content="$store.getters.getNumberOfItemsInCart" color="success">
                    <div class="topBarBtn sideCartIgnore" @click="cartBtnClicked" @mouseover="$refs.cartAnim2.play()" @mouseleave="$refs.cartAnim2.stop()">
                        <Vue3Lottie class="topBarBtnDes sideCartIgnore"
                            ref="cartAnim2"
                            :animationData="cartAnim"
                            width="23px" height="21px"
                            :loop="true"
                            :speed="0.5"
                            :autoPlay="false"/>
                    </div>
                </v-badge>
            </div>
            <div class="verticalLineBreak"></div>
            <div ref="burgerMenuBtnContainer">
                <v-icon @click="openBurgerMenu"
                    icon="mdi-menu"
                    size="small"
                    class="menuBtn">
                </v-icon>
            </div>
        </div>

        <div class="burgerMenuContainer" :class="{'burgerMenuContainer--open' : burgerMenuOpen}"
            ref="burgerMenuContainer" tabindex="0">

            <div class="fxDisplay fxDirRow burgerMenuTopBar">
                <router-link style="width: 46px; height: 24px;" to="/"><img src="../assets/imgs/GG-logo-burger.svg"></router-link>
                <div style="position: absolute; right: 60px; height: 100%; width: 1px; background: white;"></div>
                <div>
                    <v-icon
                        icon="mdi-close"
                        color="white"
                        size="large"
                        class="closeBurgerMenuBtn"
                        @click="burgerMenuClosed">
                    </v-icon>
                </div>
            </div>
            <div class="fxDisplay fxDirCol" style="border-bottom: 1px solid white; padding-top: 5px;">
                <router-link class="navLink" to="/" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'home'}">home</router-link> 
                <router-link class="navLink" to="/shop" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'shop'}">shop</router-link>
                <router-link class="navLink" to="/blogs" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'blogs'}">learn</router-link>
                <router-link class="navLink" to="/about" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'about us'}">mission</router-link>
            </div>
            <div class="fxDisplay fxDirCol" style="border-bottom: 1px solid white; padding-top: 5px;">
                <div v-if="$store.getters.getIsMod">
                    <router-link class="navLink" to="/portal" tabindex="-1" @click="burgerMenuOpen = false">backHome</router-link> 
                </div>
                <div v-if="!$store.getters.getIsLoggedIn" class="fxDisplay fxDirCol" tabindex="-1">
                    <router-link class="navLink" to="/login" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'login'}">login</router-link>
                    <router-link class="navLink" to="/signup" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'sign up'}">sign up</router-link> 
                </div>
                <div v-if="$store.getters.getIsLoggedIn" class="fxDisplay fxDirCol" tabindex="-1">
                    <router-link  class="navLink" to="/account" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'account'}">account</router-link> 
                    <router-link  class="navLink" to="/" @click="logOut" tabindex="-1">log out</router-link> 
                </div>
            </div>
            <div class="fxDisplay fxDirCol" style="border-bottom: 1px solid white; padding-top: 5px;">
                <router-link class="navLink" to="/privacy-policy" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'privacy-policy'}">privacy policy</router-link> 
                <router-link class="navLink" to="/terms-of-service" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'terms-of-service'}">terms & conditions</router-link>
                <router-link class="navLink" to="/delivery-returns" tabindex="-1" @click="burgerMenuOpen = false" :class="{'navLink--selected': getNavBarPageSelected === 'delivery-returns'}">delivery & returns</router-link>
            </div>
        </div>

    </div>
</template>

<script>
import $ from 'jquery';
import { baseURL } from '../envconfig.js';
import cartAnim from '@/assets/imgs/navbar/system-regular-6-shopping.json';
import accAnim from '@/assets/imgs/navbar/system-regular-8-account.json';
import shopAnim from '@/assets/imgs/navbar/system-regular-153-store.json';

export default {
    name: 'NavBar',
    components: {
    },
    data () {
        return {
            burgerMenuOpen : false,
            accountModal: false,

            //anims
            cartAnim: cartAnim,
            accAnim: accAnim,
            shopAnim: shopAnim,
        }
    },
    props : {
        accountModalOpen: 
        {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods : {

        accountModalClicked : function (route)
        {
            this.$emit('changeAccountModalState', false);
            this.$router.push(`/${route}`);
        },
        cartBtnClicked: function ()
        {
            if( this.validSideCartPage ) 
            {   
                this.$emit('changeSideCartState');
            }
        },
        burgerMenuClosed: function ()
        {
            this.burgerMenuOpen = false;
        },  
        openBurgerMenu: function ()
        {
            this.burgerMenuOpen = true;
            this.$nextTick(() => {
                document.body.style.overflow = "hidden";
            });
        },
        sendLoggedOutAlert : function()
        {
            this.$emit('showAlert', {
                type: "info",
                title: "You have been logged out!",
                time: 2000,
                width: "330px"
            })
        },  
        logOut : function () 
        {
            this.burgerMenuOpen = false
            $.ajax({
                url: `${baseURL}/auth/logout`,
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                xhrFields: {
                    withCredentials: true
                }
            })
            .then( function (data)  {
                // Handle successful response
                // console.log(data);
                if( data === "OK" )
                {
                    this.sendLoggedOutAlert();
                    this.$store.dispatch('isUserLoggedIn');
                    this.$store.dispatch('isUserMod');
                    setTimeout(function () {
                        this.$router.push('/');
                    }.bind(this), 100);
                }
            }.bind(this))
            .fail(function() { //jqXHR, textStatus, errorThrown
                // Handle failed response
                // console.log(jqXHR);
                // console.log(textStatus);
                // console.log(errorThrown);
            }.bind(this));
        }
    },
    computed : {
        validSideCartPage : function ()
        {
            return this.$store.getters.getValidSideCartScreen;
        },
        getNavBarPageSelected: function ()
        {
            return this.$store.getters.getNavBarPageSelected;
        }
    },
    watch: {
    },
    mounted () {
    }
}
</script>

<style lang="scss" scoped>

.navContainer
{
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    transition: height 0.2s ease;
    width: 100%;
    padding: 34.5px 10px;
    background-color: rgba($color: $ivory, $alpha: 0.8);
    border-bottom: $forest 1px solid;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.navContainer--blur
{
    backdrop-filter: blur(2px);
}

.emptySpacer {
  width: 122px;
  height: 100%;
  transition: width 0.3s ease;
}

.ggLogoMain
{
    
}

.ggLogoMobile
{
    display: none;
}

@media (max-width: 900px) {
    .emptySpacer {
        width: 10px;
    }

    .ggLogoMain
    {
        display: none;
    }
    .ggLogoMobile
    {
        display: block;
        width: 48px;
        height: 32px;
    }
    
}

.left
{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: start;
}

.rightDesktop {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-right: 10px;
}

.rightMobile
{
    display: none;
}

.navLink
{
    font-size: 16px;
    font-family: Hind-Bold, sans-serif;
    font-weight: bold;
    color: $forest;
    text-decoration: none;
    padding: 0px 16px;
    white-space: nowrap;
}

.navLink:hover
{
    color: $plant;
}

.navLink--selected
{
    color: $zest !important;
}

.cart{
    padding: 0px 0px;
}

.topBarBtnDes
{
    background: rgba($color: $ivory, $alpha: 0);
    color: $dirt;
    // margin-top: 10px; //used to position badge
    // margin-right: 2px; //used to position badge
}

.topBarBtn
{
    padding: 11px;
    background: white;
    border-radius: 100px;
    cursor: pointer;
}

.menuBtn
{
    background: rgba($color: $ivory, $alpha: 0);
    color: $forest;
    margin-top: 5px; //used to position badge
}

.verticalLineBreak
{
    width: 1px;
    background: $forest;
    height: 120px;
    margin: 0px 30px;
}

.horizontalLineBreak
{
    width: 110px;
    background: $white;
    height: 1px;
    margin: 10px 0px; 
}

.burgerMenuBtnContainer
{
    display: none;
}

.burgerMenuContainer
{
    display: none;
}

.accountModal
{
    position: absolute;
    z-index: 3;
    width: 100%;
    max-width: 320px;
    height: fit-content;
    padding: 32px;
    background-color: $ivory;
    color: $forest;
    border: 1px solid $forest;
    border-top: 0px;
    top: 120px;
    right: 115px;
    text-align: start;
    gap: 16px;
}

.primaryLoginBtn
{
  background: $plant;
  color: $white;
  border-radius: 75px;
  text-transform: none;
  font-family: new-order, sans-serif;
  font-weight: normal;
  font-size: 16px;
  // padding: 15px 40px;
  height: 40px;
  width: 300px;
}

.secondaryLoginBtn
{
    background: $white;
    color: $forest;
}

@media (max-width: 730px) //mobile mode for nav bar
{
    .accountModal
    {
        top: 76px;
        right: 0;
        border-right: 0px;
    }
    .navContainer
    {
        height: 76px;
    }

    .verticalLineBreak
    {
        height: 76px;
        margin: 0px 12px;
    }
    .rightDesktop
    {
        display: none;
    }

    .rightMobile
    {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }
    .burgerMenuContainer
    {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 75px;
        right: 0px;
        width: 110px;
        text-align: left;
        padding: 0px 0px;
        background: $ivory;
        border: $forest 1px solid;

        opacity: 0;
        pointer-events: none;
    }
    
    .burgerMenuContainer--open
    {
        display: flex;
        opacity: 1;
        pointer-events: all;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999; /* Ensure it's on top of other content */
        background-color: $forest;
    }

    .burgerMenuTopBar
    {
        position: relative;
        justify-content: space-between; 
        border-bottom: 2px solid white;
        align-items: center;
        padding: 26px 16px;
        width: 100%;
        height: 76px;
    }

    .closeBurgerMenuBtn {
        cursor: pointer;
    }

    .navLink
    {
        padding: 16px 16px;
        font-family: Hind-Bold, sans-serif;
        font-weight: bold;
        color: $white;
    }

    .navLink--legacy{
        color: $forest;
    }
}

.moon-enter-active,
.moon-leave-active {
  transition: opacity 0.5s ease;
}

.moon-enter-from,
.moon-leave-to {
  opacity: 0;
}

:deep(button){
  padding-top:3px;
}

</style>