import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager } from 'vue-meta'; // Import createMetaManager

// vue-gtag
import VueGtag from 'vue-gtag';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import PrimeVue from 'primevue/config'
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core CSS
import "primeicons/primeicons.css"; //icons

import Vue3Lottie from 'vue3-lottie'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
})


import { store } from './stores/store.js'; //vueX global store import

//Setting up persisted cart array
//This will save store cart array to localstorage before page closed.
window.addEventListener('beforeunload', () => {
  const cart = store.getters.getCartItemIDsAndQuantity;
  localStorage.setItem('cart', JSON.stringify(cart))
})

// On initial load, get cart array from localStorage if it exists, and load its value into the store.
const cartArr = JSON.parse(localStorage.getItem('cart'));
if (cartArr && cartArr.length > 0) {
  //we reload the cart details from the stored item ids and quantities. At this point we do not check if the quantities are within our availablequantity yet.
  store.dispatch('refreshCartArrayValuesFromItemIDsWithoutQuantityCheck', cartArr); 
}


const root = createApp(App).use(router).use( VueGtag, { 
  pageTrackerExcludedRoutes: [
    'OrdersAdmin', 
    'portal',
    'ItemAdmin',
    'ItemEdit',
    'InventoryEdit'
  ],
  config: { id: "G-XMPTDPZX8J" }}, router).use(PrimeVue)
  .use(vuetify).use(Vue3Lottie).use(store).use(createMetaManager()).mount('#app');

//general click event listener to check if click is outside the cart component, to close it.
document.addEventListener('click', (event) => {
  const cartContainer = document.querySelector('.cartContainer');
  // Check if the click event did not originate from the cart container or its children
  //checking if click originated from within the sideCart. Also have an exception class called sideCartIgnore which,
  //can be applied directly to the element clicked or its parent (in the case of delete button in cart) to avoid 
  //closing the cart with that click event.
  if ( root.$data.sideCartOpen === true && cartContainer && !cartContainer.contains(event.target) 
    && !event.target.closest('.sideCartIgnore') && !event.target.parentElement.classList.contains('.sideCartIgnore')) 
  {
    root.$data.sideCartOpen = false;
  }

  const accountModal = document.querySelector('.accountModal');
  // Check if the click event did not originate from the cart container or its children
  //checking if click originated from within the sideCart. Also have an exception class called sideCartIgnore which,
  //can be applied directly to the element clicked or its parent (in the case of delete button in cart) to avoid 
  //closing the cart with that click event.
  if ( root.$data.accountModalOpen === true && accountModal && !accountModal.contains(event.target) 
    && !event.target.closest('.accountModalIgnore') && !event.target.parentElement.classList.contains('.accountModalIgnore')) 
  {
    root.$data.accountModalOpen = false;
  }
});