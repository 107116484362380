import {createStore} from 'vuex';
import $ from 'jquery';
import { baseURL } from '../envconfig.js';

//utility functions
const getDateSuffix = (date) => {
    if (date >= 11 && date <= 13) {
        return 'th';
    }
    switch (date % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

export const store = createStore({
    state: {
        cart : [],
        isLoggedIn: false,
        isMod: false,
        validSideCartScreen: false,
        navBarPageSelected: 'home',
        cookiesState: false,
    },
    getters: {
        getOrderCutoffTime(){
            const now = new Date();
            const day = now.getDay(); // 0 (Sunday) to 6 (Saturday)
            const hour = now.getHours();

            let cutoffTime = new Date(now);
            let deliveryDay;
            const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            if (day === 0 || ( day === 6 && hour >= 14 )) {
                // Sunday or Saturday after 2pm - we deliver it on tuesday
                const daysUntilMonday = day === 0 ? 1 : 2; // Days until next Monday
                cutoffTime.setDate(now.getDate() + daysUntilMonday); // Next Monday
                cutoffTime.setHours(15, 0, 0, 0); // Monday at 3 PM
                deliveryDay = dayNames[(day + daysUntilMonday + 1) % 7]; // Tuesday
            } 
            else if(day === 6 && hour < 14){
                //Saturday before 2 pm - we deliver it on monday
                cutoffTime.setHours(14, 0, 0, 0); // Today (Saturday) at 2 PM
                deliveryDay = dayNames[1]; // Monday
            }
            else if( day === 5 && hour > 15 )
            {
                //Friday after 3pm - we deliver it on monday
                cutoffTime.setDate(now.getDate() + 1); // Next day
                cutoffTime.setHours(14, 0, 0, 0); // Today (Saturday) at 2 PM
                deliveryDay = dayNames[1]; // Monday
            }
            else {
                // Weekday
                if (hour < 15) {
                    // Before 3 PM - we deliver it next day
                    cutoffTime.setHours(15, 0, 0, 0); // Today at 3 PM
                    deliveryDay = dayNames[(day + 1) % 7]; // Tomorrow
                } 
                else {
                    // After 3 PM or Exactly 3 PM - we deliver it in 2 days
                    cutoffTime.setDate(now.getDate() + 1); // Next day
                    cutoffTime.setHours(15, 0, 0, 0); // Next day at 3 PM
                    deliveryDay = dayNames[(day + 2) % 7]; // Day after tomorrow
                }
            }

            const timeRemaining = cutoffTime - now;
            const totalHoursRemaining = Math.floor(timeRemaining / (1000 * 60 * 60));
            // const hoursRemaining = totalHoursRemaining % 24;
            const minutesRemaining = Math.floor((timeRemaining / (1000 * 60)) % 60);

            const deliveryDate = cutoffTime.getDate() + 1;
            const suffix = getDateSuffix(deliveryDate);

            return `${totalHoursRemaining}h ${minutesRemaining}m to receive on ${deliveryDay}, ${deliveryDate}${suffix}`;
        },
        getNavBarPageSelected(state)
        {
            return state.navBarPageSelected;
        },
        getCookieConsentState(state)
        {
            return state.cookiesState;
        },
        getValidSideCartScreen(state)
        {
            return state.validSideCartScreen;
        },
        getNumberOfItemsInCart(state)
        {
            return state.cart.length;
        },
        getCart(state) 
        {
            return state.cart;
        },
        getCartItemIDsAndQuantity(state)
        {
            //saves all itemIDs and quantities in the cart to localstorage. Which allows us to reload the up to date 
            //values for each itemID when the user re-visits the site. This ensures we dont have old
            //outdated data in the localstorage.
            let itemIDs = [];
            for(let x = 0; x< state.cart.length; x++)
            {
                itemIDs.push(
                    {
                        ItemID: state.cart[x].ItemID,
                        Quantity: state.cart[x].Quantity
                    }
                );
            }
            return itemIDs;
        },
        getIsLoggedIn(state)
        {
            return state.isLoggedIn;
        },
        getIsMod(state)
        {
            return state.isMod;
        }
    },
    mutations: { //mutations always need to run synchronously. Actions can be asynchronous.
        updateCookieConsentState ( state, decision )
        {
            if(decision !== undefined && (decision === true || decision === false) )
            {
                state.cookiesState = decision;
                return true;
            }
            return false;
        },
        updateCurrentNavBarPageSelected (state, page) {
            state.navBarPageSelected = page;
        },
        updateValidSideCartScreenState (state, valid) {
            if(valid !== undefined && (valid === true || valid === false) )
            {
                state.validSideCartScreen = valid;
                return true;
            }
            return false;
        },
        addToCart (state, item) {
            if(item)
            {   
                state.cart.push(item);
                return true;
            }
            return false; //invalid call of method
        },
        removeFromCart (state, itemID) {
            if(itemID)
            {   
                let items = state.cart.filter(item => item.ItemID !== itemID); //removing item from cart list
                state.cart = items; //setting cart to item array without removed item.
                return true;
            }
            return false; //invalid call of method
        },
        updateQuantityOfItem (state, updatePayload) {
            if(updatePayload && updatePayload.ItemID && updatePayload.Quantity)
            {   
                for( let x = 0; x < state.cart.length; x++ )
                {
                    if( state.cart[x].ItemID === updatePayload.ItemID )
                    {
                        state.cart[x].Quantity = updatePayload.Quantity; 
                        // if( updatePayload.Quantity <= state.cart[x].AvailableQuantity )
                        // {
                        //     //case that updateQuantityAmount is within the availableQuantity
                        //     state.cart[x].Quantity = updatePayload.Quantity; 
                        //     return true;
                        // }
                        // else
                        // {
                        //     //case that updateQuantityAmount is more than the availableQuantity
                        //     //so we set the quantity to the actual available quantity.
                        //     state.cart[x].Quantity = state.cart[x].AvailableQuantity;
                        //     return false;
                        // }
                    }
                }
                return false; //was unable to find itemID in cart so unable to update quantity.
            }
            return false; //invalid call of method.
        },
        checkIfItemIsAlreadyInCart (state, ItemID) {
            for( let x = 0; x < state.cart.length; x++ )
            {
                if( state.cart[x].ItemID === ItemID )
                {
                    let res = {
                        "inCart" : true,
                        "quantity" : state.cart[x].Quantity
                    }
                    return res;
                }
            }
            //case that cart has nothing in it, or was itemID was not found in cart.
            let res = {
                "inCart" : false,
                "quantity" : 0
            }
            return res;
        },
        updateLoggedIn (state, loggedIn)
        {
            state.isLoggedIn = loggedIn;
        },
        updateIsMod ( state, isMod )
        {
            state.isMod = isMod;
        },
        setCartArrayValue (state, cart) //used primarily to reload cart from localStorage, to be able to replace entire cart object.
        {
            state.cart = cart;
        },
    },
    actions : { //action is a middelman before we commit the mutation
        //this.$store.dispatch('name', payload)
        updateCookieConsentState(context, decision)
        {
            context.commit('updateCookieConsentState', decision);
        },
        updateCurrentNavBarPageSelected (context, page) {
            context.commit('updateCurrentNavBarPageSelected', page);
        },
        updateValidSideCartScreenState(context, valid) {
            context.commit('updateValidSideCartScreenState', valid);
        },
        addToCart(context, item) {
            context.commit('addToCart', item);
            let state = context.state;
            
            if( state.cookiesState )
            {
                if( window.fbq )
                {
                    const totalValue = state.cart.reduce((sum, item) => sum + item.Price, 0);
                    window.fbq('track', "AddToCart", { content_ids: state.cart.map(item => item.ItemID), content: state.cart.map(item => ({
                        Title: item.Title,
                        ItemID: item.ItemID,
                        Price: item.Price,
                        Quantity: item.Quantity
                    })), value: totalValue, currency: 'EUR'});
                }

                if( window.gtag )
                {
                    window.gtag('event', 'add_to_cart', {
                        currency: 'EUR',
                        value: item.price,
                        items: [{
                            item_id: item.ItemID,
                            item_name: item.Title,
                            currency: 'EUR',
                            price: item.Price,
                            quantity: item.Quantity
                        }]
                    });
                }
            }    
            //returns true if newely added item to cart is found, and false if item was not successfully added to state.
            return state.cart.some(cartItem => cartItem.ItemID === item.ItemID);
        },
        removeFromCart( context, itemID )
        {
            context.commit('removeFromCart', itemID);
            let state = context.state;
            //returns true if removed item is NOT found in cart, and tfalse if item was not successfully REMOVED from the state.
            return !state.cart.some(cartItem => cartItem.ItemID === itemID);
        },
        updateQuantityOfItem(context, updatePayload) 
        {
            context.commit('updateQuantityOfItem',updatePayload);
            let state = context.state;
            
            if( window.fbq && state.cookiesState )
            {
                const totalValue = state.cart.reduce((sum, item) => sum + item.Price, 0);
                window.fbq('track', "AddToCart", { content_ids: state.cart.map(item => item.ItemID), content: state.cart.map(item => ({
                    Title: item.Title,
                    ItemID: item.ItemID,
                    Price: item.Price,
                    Quantity: item.Quantity
                })), value: totalValue, currency: 'EUR'});
            }    
            //returns true if quantity of item was updated Correctly.
            return state.cart.some(cartItem => cartItem.ItemID === updatePayload.ItemID && cartItem.Quantity === updatePayload.Quantity);
        },
        checkIfItemIsAlreadyInCart(context, ItemID) {
            let state = context.state;
            for( let x = 0; x < state.cart.length; x++ )
            {
                if( state.cart[x].ItemID === ItemID )
                {
                    let res = {
                        "inCart" : true,
                        "quantity" : state.cart[x].Quantity
                    }
                    return res;
                }
            }
            //case that cart has nothing in it, or was itemID was not found in cart.
            let res = {
                "inCart" : false,
                "quantity" : 0
            }
            return res;
        },
        async refreshCartArrayValuesFromItemIDs(context, itemIDsAndQuantity)
        {
            //ItemIDs used to get latest data from the API related to the itemIDs.
            const itemIds = itemIDsAndQuantity.map(item => item.ItemID);
            //Quantities are checked against our Available quantities.
            //If stock >= quantity, then send back original quantity. If stock < quantity, then send back maximum availble.
            //if stock === 0 send back quantity = 0. This will then ommit it from the cart object.
            // const quantities = itemIDsAndQuantity.map(item => item.Quantity); 
            //console.log(quantities);

            if( itemIds && itemIds.length > 0)
            {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: `${baseURL}/items/bulkItems`,
                        method: "POST",
                        headers: {
                        "Content-Type": "application/json"
                        },
                        data: JSON.stringify({
                        "ItemIDs": itemIds
                        }),
                        xhrFields: {
                        withCredentials: true
                        }
                    })
                    .then( function (data)  {
                        // Handle successful response
                        if( data && data.length > 0) 
                        {
                            //console.log("Retrieved cart items from server.");
                            let updatedCartWithQuantities = [];
                            for(let x = 0; x < data.length; x++ )
                            {
                                //here i am merging the quantity back to the item info, so that we retain the user selected quantity.
                                const { Quantity } = itemIDsAndQuantity.find(item => item.ItemID === data[x].ItemID);
                                if( Quantity && Quantity > 0 && data[x] && data[x].AvailableQuantity)
                                {
                                    let mergedObj = data[x];
                                    if( Quantity <= data[x].AvailableQuantity ) //case that our stock is enough or more than enough to support the quantity saved.
                                    {
                                        mergedObj.Quantity = Quantity;
                                    }
                                    else if ( Quantity > data[x].AvailableQuantity ) //case that our stock is not to support the quantity saved.
                                    {
                                        //in this case we set the quantity to the maximum available quantity.
                                        mergedObj.Quantity = data[x].AvailableQuantity;
                                    }
                                    else //case that available quantity is null we dont re-add item to the cart.
                                    {
                                        continue;
                                    }
                                    updatedCartWithQuantities.push(mergedObj);
                                }
                            } 
                            
                            context.commit('setCartArrayValue', updatedCartWithQuantities);
                            resolve();
                        }
                        else
                        {
                            //console.log("Unable to get itemdata from itemIDs in cart.");
                            reject("Unable to get itemdata from itemIDs in cart.");
                        }
                        
                    }.bind(context))
                    .fail(function(errorThrown) { //jqXHR, textStatus
                        // Handle failed response
                        // console.log(jqXHR);
                        // console.log(textStatus);
                        // console.log(errorThrown);
                        context.commit('setCartArrayValue', []);
                        reject(errorThrown);
                        //console.log("Unable retrieve cart from ids");
                    }.bind(context));
                });
            }
            else
            {
                context.commit('setCartArrayValue', []);
            }
            
        },
        refreshCartArrayValuesFromItemIDsWithoutQuantityCheck(context, itemIDsAndQuantity)
        {
            //quanitites not checked IN THIS METHOD. This purpose built for the hard reload case of a (user mainjs). The quantity check
            //version of this method is 'refreshCartArrayValuesFromItemIDs' which is called when entering the cart.

            //ItemIDs used to get latest data from the API related to the itemIDs.
            const itemIds = itemIDsAndQuantity.map(item => item.ItemID);
            // const quantities = itemIDsAndQuantity.map(item => item.Quantity); 
            //console.log(quantities);

            if( itemIds && itemIds.length > 0)
            {
                $.ajax({
                    url: `${baseURL}/items/bulkItems`,
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    data: JSON.stringify({
                      "ItemIDs": itemIds
                    }),
                    xhrFields: {
                      withCredentials: true
                    }
                  })
                  .then( function (data)  {
                    // Handle successful response
                    if( data && data.length > 0) 
                    {
                        //console.log("Retrieved cart items from server.");
                        let refreshedCart = [];
                        for(let x = 0; x < data.length; x++ )
                        {
                            //here i am merging the quantity back to the item info, so that we retain the user selected quantity.
                            const { Quantity } = itemIDsAndQuantity.find(item => item.ItemID === data[x].ItemID);
                            if( Quantity && Quantity > 0 && data[x] && data[x].AvailableQuantity)
                            {
                                let mergedObj = data[x];
                                mergedObj.Quantity = Quantity; //simply setting quantity to the user defined amount.

                                refreshedCart.push(mergedObj);
                            }
                        } 
                        
                        context.commit('setCartArrayValue', refreshedCart);
                    }
                    else
                    {
                      //console.log("Unable to get itemdata from itemIDs in cart.");
                    }
                    
                  }.bind(context))
                  .fail(function() { //jqXHR, textStatus, errorThrown
                    // Handle failed response
                    // console.log(jqXHR);
                    // console.log(textStatus);
                    // console.log(errorThrown);
                    context.commit('setCartArrayValue', []);
                    //console.log("Unable retrieve cart from ids");
                  }.bind(context));
            }
            else
            {
                context.commit('setCartArrayValue', []);
            }
            
        },
        isUserLoggedIn( context )
        {
            $.ajax({
                url: `${baseURL}/auth/loggedin`,
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                },
                xhrFields: {
                  withCredentials: true
                }
            })
            .then( function (data)  {
            // Handle successful response
                if( data && data.loggedIn )
                {
                    //console.log("User logged in check: LoggedIn TRUE");
                    context.commit('updateLoggedIn', true);

                    if( context.state.cookiesState )
                    {
                        window.sendinblue.identify(
                            data.email,
                            {
                                first_name: data.name,
                                last_name: data.surname
                            }
                        );
                    }
                }
                else
                {
                    //console.log("User logged in check: LoggedIn FALSE");
                    context.commit('updateLoggedIn', false);
                }
                
            }.bind(context))
            .fail(function() { //jqXHR, textStatus, errorThrown
                // Handle failed response
                // console.log(jqXHR);
                // console.log(textStatus);
                // console.log(errorThrown);
                //console.log("Unable to check if user is logged in");
                context.commit('updateLoggedIn', false);
            }.bind(context));
        },
        isUserLoggedInSync( context ) //will return a promise to allow the calling method to know when the values have been updated. (used in router)
        {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: `${baseURL}/auth/loggedin`,
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    xhrFields: {
                      withCredentials: true
                    }
                })
                .then( function (data)  {
                    // Handle successful response
                    if( data && data.loggedIn )
                    {
                        // console.log("User logged in check: LoggedIn TRUE");
                        context.commit('updateLoggedIn', true);

                        if( context.state.cookiesState )
                        {
                            window.sendinblue.identify(
                                data.email,
                                {
                                    first_name: data.name,
                                    last_name: data.surname
                                }
                            );
                        }
                    }
                    else
                    {
                        // console.log("User logged in check: LoggedIn FALSE");
                        context.commit('updateLoggedIn', false);
                    }
                    resolve(); // Resolve the Promise to let the calling method know the user values in the store are updated.
                }.bind(context))
                .fail(function() { //jqXHR, textStatus, errorThrown
                    // Handle failed response
                    // console.log(jqXHR);
                    // console.log(textStatus);
                    // console.log(errorThrown);
                    // console.log("Unable to check if user is logged in");
                    context.commit('updateLoggedIn', false);
                    reject(); // Reject the Promise to let the calling method know that we were unable to update the user store values.
                }.bind(context));
            });
        },
        isUserMod( context )
        {
            $.ajax({
                url: `${baseURL}/auth/mod`,
                method: "GET",
                headers: {
                  "Content-Type": "application/json"
                },
                xhrFields: {
                  withCredentials: true
                }
              })
              .then( function (data)  {
                // Handle successful response
                if( data && data.isMod )
                {
                    // console.log("User ismod in check: isMOD TRUE");
                    context.commit('updateIsMod', true); //if user is a mod then they are also logged in.
                    context.commit('updateLoggedIn', true);
                }
                else
                {
                    // console.log("User ismod in check: isMOD FALSE");
                    context.commit('updateIsMod', false);
                }
                
              }.bind(context))
              .fail(function() { //jqXHR, textStatus, errorThrown
                    // Handle failed response
                    // console.log(jqXHR);
                    // console.log(textStatus);
                    // console.log(errorThrown);
                    // console.log("Unable to check if user is mod in");
                    context.commit('updateIsMod', false);
              }.bind(context));
        },
        isUserModSync( context ) //will return a promise to allow the calling method to know when the values have been updated. (used in router)
        {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: `${baseURL}/auth/mod`,
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    xhrFields: {
                        withCredentials: true
                    }
                })
                .then(function (data) {
                    // Handle successful response
                    if (data && data.isMod) {
                        // console.log("User isMod in check: isMod TRUE");
                        context.commit('updateIsMod', true);
                        context.commit('updateLoggedIn', true);
                    } else {
                        // console.log("User isMod in check: isMod FALSE");
                        context.commit('updateIsMod', false);
                    }
                    resolve(); // Resolve the Promise to let the calling method know the user values in the store are updated.
                }.bind(context))
                .fail(function () { //jqXHR, textStatus, errorThrown
                    // Handle failed response
                    // console.log(jqXHR);
                    // console.log(textStatus);
                    // console.log(errorThrown);
                    // console.log("Unable to check if user is mod");
                    context.commit('updateIsMod', false);
                    reject(); // Reject the Promise to let the calling method know that we were unable to update the user store values.
                }.bind(context));
            });
        },
        clearCart( context )
        {
            context.commit('setCartArrayValue', []);
        }
    }

});